var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page-layout", { attrs: { avatar: _vm.avatar } }, [
    _c("div", { attrs: { slot: "headerContent" }, slot: "headerContent" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.timeFix) + "，" + _vm._s(_vm.nickname())),
        _c("span", { staticClass: "welcome-text" }, [
          _vm._v("，" + _vm._s(_vm.welcome())),
        ]),
      ]),
      _c("div", [_vm._v("前端工程师 | 蚂蚁金服 - 某某某事业群 - VUE平台")]),
    ]),
    _c(
      "div",
      { attrs: { slot: "extra" }, slot: "extra" },
      [
        _c(
          "a-row",
          { staticClass: "more-info" },
          [
            _c(
              "a-col",
              { attrs: { span: 8 } },
              [
                _c("head-info", {
                  attrs: {
                    title: "项目数",
                    content: "56",
                    center: false,
                    bordered: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 8 } },
              [
                _c("head-info", {
                  attrs: {
                    title: "团队排名",
                    content: "8/24",
                    center: false,
                    bordered: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 8 } },
              [
                _c("head-info", {
                  attrs: { title: "项目访问", content: "2,223", center: false },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "a-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "a-col",
              { attrs: { xl: 16, lg: 24, md: 24, sm: 24, xs: 24 } },
              [
                _c(
                  "a-card",
                  {
                    staticClass: "project-list",
                    staticStyle: { "margin-bottom": "24px" },
                    attrs: {
                      loading: _vm.loading,
                      bordered: false,
                      title: "进行中的项目",
                      "body-style": { padding: 0 },
                    },
                  },
                  [
                    _c("a", { attrs: { slot: "extra" }, slot: "extra" }, [
                      _vm._v("全部项目"),
                    ]),
                    _c(
                      "div",
                      _vm._l(_vm.projects, function (item, i) {
                        return _c(
                          "a-card-grid",
                          { key: i, staticClass: "project-card-grid" },
                          [
                            _c(
                              "a-card",
                              {
                                attrs: {
                                  bordered: false,
                                  "body-style": { padding: 0 },
                                },
                              },
                              [
                                _c("a-card-meta", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-title",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [
                                      _c("a-avatar", {
                                        attrs: {
                                          size: "small",
                                          src: item.cover,
                                        },
                                      }),
                                      _c("a", [_vm._v(_vm._s(item.title))]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-description",
                                      attrs: { slot: "description" },
                                      slot: "description",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.description) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "project-item" }, [
                                  _c("a", { attrs: { href: "/#/" } }, [
                                    _vm._v("科学搬砖组"),
                                  ]),
                                  _c("span", { staticClass: "datetime" }, [
                                    _vm._v("9小时前"),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]
                ),
                _c(
                  "a-card",
                  {
                    attrs: {
                      loading: _vm.loading,
                      title: "动态",
                      bordered: false,
                    },
                  },
                  [
                    _c(
                      "a-list",
                      _vm._l(_vm.activities, function (item, index) {
                        return _c(
                          "a-list-item",
                          { key: index },
                          [
                            _c(
                              "a-list-item-meta",
                              [
                                _c("a-avatar", {
                                  attrs: {
                                    slot: "avatar",
                                    src: item.user.avatar,
                                  },
                                  slot: "avatar",
                                }),
                                _c(
                                  "div",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.user.nickname)),
                                    ]),
                                    _vm._v(" \n                  在 "),
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v(_vm._s(item.project.name)),
                                    ]),
                                    _vm._v(" \n                  "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.project.action)),
                                    ]),
                                    _vm._v(" \n                  "),
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v(_vm._s(item.project.event)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "description" },
                                    slot: "description",
                                  },
                                  [_vm._v(_vm._s(item.time))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-col",
              {
                staticStyle: { padding: "0 12px" },
                attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
              },
              [
                _c(
                  "a-card",
                  {
                    staticStyle: { "margin-bottom": "24px" },
                    attrs: {
                      title: "快速开始 / 便捷导航",
                      bordered: false,
                      "body-style": { padding: 0 },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "item-group" },
                      [
                        _c("a", [_vm._v("操作一")]),
                        _c("a", [_vm._v("操作二")]),
                        _c("a", [_vm._v("操作三")]),
                        _c("a", [_vm._v("操作四")]),
                        _c("a", [_vm._v("操作五")]),
                        _c("a", [_vm._v("操作六")]),
                        _c(
                          "a-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              ghost: "",
                              icon: "plus",
                            },
                          },
                          [_vm._v("添加")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "a-card",
                  {
                    staticStyle: { "margin-bottom": "24px" },
                    attrs: {
                      title: "XX 指数",
                      loading: _vm.radarLoading,
                      bordered: false,
                      "body-style": { padding: 0 },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "min-height": "400px" } },
                      [_c("radar", { attrs: { data: _vm.radarData } })],
                      1
                    ),
                  ]
                ),
                _c(
                  "a-card",
                  {
                    attrs: {
                      loading: _vm.loading,
                      title: "团队",
                      bordered: false,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "members" },
                      [
                        _c(
                          "a-row",
                          _vm._l(_vm.teams, function (item, index) {
                            return _c(
                              "a-col",
                              { key: index, attrs: { span: 12 } },
                              [
                                _c(
                                  "a",
                                  [
                                    _c("a-avatar", {
                                      attrs: {
                                        size: "small",
                                        src: item.avatar,
                                      },
                                    }),
                                    _c("span", { staticClass: "member" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }